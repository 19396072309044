@import './variables.scss';

.flex {
  display: flex;
}

.returnButton {
  margin: 0;
  padding: 0 12px;
  background: none;
  text-decoration: underline;
  -webkit-appearance: none;
  border: none;
  font-size: 16px;
  line-height: 22px;
  text-align: left;

  cursor: pointer;
}
