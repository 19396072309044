@import 'ui/styles/add-form.module.scss';

.spacer {
  margin-top: 1em;
}

.checkbox {
  margin-top: 8px;
}

.marginHorizontalNoMobile {
  margin-left: 16px;
  margin-right: 16px;
  @include mobile {
    margin-left: 0;
    margin-right: 0;
  }
}

.separator {
  margin-top: 36px;
  margin-bottom: 36px;
}

.bolder {
  font-weight: 800;
}

.marginLeft {
  margin-left: 16px;
}
