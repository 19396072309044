@import 'ui/styles/index.scss';

.container {
  padding: 32px 64px;
  width: 100%;
  height: auto;
}

.searchRow {
  display: flex;
  padding: 10px 0;
  align-items: center;
}

.select {
  max-width: 300px;
}

.selectModal {
  width: 100%;
  margin-bottom: 16px;
}

.buttonModal {
  width: 100%;
  margin: 32px 0;
}

.spacer {
  flex: 1;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
}

@include mobile {
  .container {
    padding: 16px;
  }
}
