@import 'ui/styles/index.scss';

.inputText {
  @extend %form-element;
  padding: 10px 15px;
  color: #333333;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

// Hide number picker on Webkit
.inputText[type='number']::-webkit-inner-spin-button,
.inputText[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Hide number picker on Firefox
.inputText[type='number'] {
  -moz-appearance: textfield;
}
