// - Colors: except for primary, secondary, etc. colors make sure to use
// http://chir.ag/projects/name-that-color

$color-primary: #1e2d3b;
$color-white: #fff;
$color-error: #fa1e1e;
$color-lightgrey: lighten(#808080, 20%);
$color-black: black;
$color-grey-alabaster: #f7f7f7;
$color-torea-bay: #0c2396;
$color-jumbo: #77777a;

/*--------------------------------------------------------------
- Media queries: Base mixins for responsive breakpoints
--------------------------------------------------------------*/
$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 1024px;
$large-desktop-width: 1200px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin under-mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin under-desktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin under-large-desktop {
  @media (max-width: #{$large-desktop-width - 1px}) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

// placeholder selector for common style on form elements
%form-element {
  width: 230px;
  @include mobile {
    width: 100%;
  }
}
